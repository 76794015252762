import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import loadable from "@loadable/component"
const Footer = loadable(() => import("../components/footer3"))

export default function PrivacyPolicy() {
  return (
    <div className="mb-6">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Refund and Cancellation Policy</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="This Refund and Cancellations Policy applies to products on EnjoyAlgorithms website."
        />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="mt-6 container mx-auto px-5 md:px-7 lg:px-8 xl:px-10 pt-5 md:pt-8">
        <h1 className="tracking-wider text-2xl mb-4 xl:text-3xl text-gray-900 text-center">
          Refund and Cancellation Policy
        </h1>
        <p className="mb-4 mt-4 tracking-wider text-gray-600">Last updated on Aug 22nd 2023</p>

        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            CODE ALGORITHMS PRIVATE LIMITED believes in helping its customers as
            far as possible and has, therefore, a liberal cancellation
            policy. Under this policy, If, for any reason, You are not
            completely satisfied with a purchase, we invite You to review our
            policy on refunds and returns. The following terms are applicable
            for any products that You purchased with Us.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Your Order Cancellation Rights
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            7 days a refund window from the course commencement date (11:59:59
            pm on the 7th day from the course commencement date), no questions
            asked. This will apply to all paid courses available on
            EnjoyAlgorithms website:
          </p>
          <p className="mb-1">
            - 16 Weeks DSA Masterclass
            (https://www.enjoyalgorithms.com/dsa-course-for-beginners-and-intermediate-learners/) 
          </p>
          <p className="mb-4">
            - 10 Weeks DSA Masterclass
            (https://www.enjoyalgorithms.com/dsa-course-for-advanced-learners/).
          </p>
          <p className="mb-4">
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. You can inform us of
            your decision by email. In case of any Refunds approved by the CODE
            ALGORITHMS PRIVATE LIMITED, it will take 6-8 days for the refund to
            be processed to the end customer.
          </p>
          <p className="mb-1">Email: contact@enjoyalgorithms.com.</p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Contact us
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
          </p>
          <p className="mb-4">By email: contact@enjoyalgorithms.com.</p>
        </div>
        <div className="mt-6">
          <Footer />
        </div>
      </div>
    </div>
  )
}
